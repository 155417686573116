import React from 'react'

import { Link, NotFoundPage, Paragraph } from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const NotFoundPageCustomizedExample = () => (
  <NotFoundPage
    heading={'Voi ei! Etsimääsi työnhakuprofiilia ei löytynyt.'}
    content={
      <>
        <Paragraph align="center">
          Olemme pahoillamme. Työnhakuprofiilia, jota yritit avata, ei syystä
          tai toisesta löytynyt.
        </Paragraph>
        <Paragraph
          align="center"
          noMargin
        >
          Palaa takaisin <Link as={LinkMock}>työntekijähakuun</Link>.
        </Paragraph>
      </>
    }
  />
)

export { NotFoundPageCustomizedExample }
