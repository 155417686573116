import React, { useContext } from 'react'

import { Link, NotFoundPage, Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Context } from '../../components/Context'
import { Section } from '../../components/Section'
import { Code } from '../../components/Code'
import { LinkMock } from '../../components/LinkMock'
import { NotFoundPageCustomizedExample } from '../../examples/react/NotFoundPageCustomizedExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => {
  const { locale } = useContext(Context)

  const linkText = {
    fi: 'Työmarkkinatorin etusivulta',
    sv: 'Jobbmarknads startsida',
    en: 'the front page of Job Market'
  }[locale || 'fi']

  return (
    <Content
      heading="NotFoundPage"
      components={[{ component: NotFoundPage }]}
      status={[{ type: 'accessible', version: '15.1.0' }]}
    >
      <Section>
        <Paragraph>
          <Code>NotFoundPage</Code>-komponenttia käytetään sivupohjissa{' '}
          <Code>Columns</Code>-komponentin tilalla.
        </Paragraph>
        <Playground>
          <NotFoundPage link={<Link as={LinkMock}>{linkText}</Link>} />
        </Playground>
      </Section>

      <Section title="Muokatulla tekstisisällöllä">
        <Paragraph>
          On suositeltavaa käyttää oletustekstiä. Vain laajemmissa
          kokonaisuuksissa, kuten työnhaussa, voi olla perusteltua muokata
          tekstiä paremmin tilanteeseen sopivaksi. <Code>NotFoundPage</Code>n
          oletusversio sisältää käännökset, muokatussa versiossa käännökset
          tulee määritellä itse.
        </Paragraph>
        <Playground example={NotFoundPageCustomizedExample} />
      </Section>
      <Section title="Saavutettavuus">
        <Paragraph>
          Huolehdi, että sivun teksti on kuvaava ja se sisältää linkin, jonka
          avulla käyttäjä pystyy palaamaan esimerkiksi etusivulle.
        </Paragraph>
      </Section>
    </Content>
  )
}

export default Page
